/**
 * unminify with: http://unminify.com/
 */
_.mixin({sortByKeys:function(a){return _(a).toPairs().sortBy().fromPairs().value()}});
_.mixin({replaceAt:function(a,b,c){return a.substr(0,c-1)+b+a.substr(c,a.length)}});
_.mixin({randomExcept:function(a,b,c){c=_.castArray(c);var d=_.random(a,b);for(;-1<c.indexOf(d);)d=_.random(a,b);return d}});
_.mixin({isOperator:function(i){return"+"==i||"-"==i||"*"==i||"/"==i||"%"==i}});
_.mixin({push:function(n,u){return n.push(u),n}});
_.mixin({pullObject:function(i,n){if(_.isArray(i)&&_.isObject(n)){var r=[];return _.each(i,function(i){_.isEqual(i,n)||r.push(i)}),r}return i}});
_.mixin({try:function(t,n){try{t()}catch(t){"function"==typeof n&&n(t)}}});
